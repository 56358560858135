<template>
  <v-dialog
    v-model="dialogForm"
    scrollable
    persistent
    content-class="dialog-pdv br-15"
  >
    <v-card>
      <v-toolbar
        elevation="0"
        dark
        class="primary-degrade white--text fix-flex"
      >
        <v-toolbar-title>
          {{ fnEditar ? "Editar planejamento" : "Criação de planejamento" }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          fab
          width="24px"
          height="24px"
          elevation="0"
          color="#fff"
          @click="dialogForm = false"
        >
          <v-icon size="18px" color="primary">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-stepper v-model="etapa" non-linear class="elevation-0 card-body">
        <v-stepper-header class="px-2">
          <v-stepper-step
            editable
            error-icon="mdi-alert-circle"
            step="1"
            :rules="regra.etapa1"
            >Dados do planejamento</v-stepper-step
          >
          <v-divider class="mx-2"></v-divider>
          <v-stepper-step editable error-icon="mdi-alert-circle" step="2"  :rules="regra.etapa2"
            >Informações adicionais</v-stepper-step
          >
          <v-divider class="mx-2"></v-divider>
          <v-stepper-step editable error-icon="mdi-alert-circle" step="3"
            >Resumo</v-stepper-step
          >
        </v-stepper-header>
        <div class="etapas-conteudo">
          <v-stepper-items class="min-h-100">
            <v-stepper-content class="min-h-100" step="1">
              <Cadastro ref="cadastro" v-model="planejamento"></Cadastro>
            </v-stepper-content>
            <v-stepper-content class="min-h-100" step="2">
              <InformacoesAdicionais
                ref="informacoesAdicionais"
                v-model="planejamento"
              ></InformacoesAdicionais>
            </v-stepper-content>
            <v-stepper-content class="min-h-100" step="3">
              <Resumo ref="resumo" v-model="planejamento"></Resumo>
            </v-stepper-content>
          </v-stepper-items>
        </div>

        <div class="d-flex card-footer pa-4">
          <v-btn
            color="yellow"
            width="130px"
            :disabled="disabledVoltar"
            @click="voltar"
            class="new-button"
            dark
          >
            <v-icon left>mdi-chevron-left-circle</v-icon>
            Voltar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            width="130px"
            @click="proximo"
            class="new-button"
          >
            {{ botao }}
            <v-icon right>{{ botaoIco }}</v-icon>
          </v-btn>
        </div>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>
<script>
import Cadastro from "./cadastro.vue";
import InformacoesAdicionais from "./informacoes_adicionais.vue";
import Resumo from "./resumo.vue";
var smartId = require("smart-id");
export default {
  components: {
    Cadastro,
    InformacoesAdicionais,
    Resumo,
  },
  props: ["value"],

  data() {
    return {
      dialogForm: false,
      etapa: 1,
      planejamento: {},
      botao: "Avançar",
      botaoIco: "mdi-chevron-right-circle",
      regra: {
        etapa1: [
          () => {
            if (this.etapa > 1) {
              return this.$refs.cadastro.validate(); // validar etapa 1
            }
            return true; //  valido
          },
        ],
        etapa2: [
          () => {
            if (this.etapa > 2) {
              console.log(this.etapa, this.$refs.informacoesAdicionais.validate())
              return this.$refs.informacoesAdicionais.validate(); // validar etapa 1
            }
            return true; //  valido
          },
        ],
      },
    };
  },
  computed: {
    disabledVoltar() {
      return this.etapa == 1;
    },
    fnEditar() {
      return this.planejamento.id_planejamento;
    },
  },
  watch: {
    etapa(valor) {
      if (valor == 1) {
        this.botao = "Avançar";
        this.botaoIco = "mdi-chevron-right-circle";
      }
      if (valor == 2) {
        this.botao = "Avançar";
        this.botaoIco = "mdi-chevron-right-circle";
      }
      if (valor == 3) {
        this.botao = "Salvar";
        this.botaoIco = "mdi-check-circle";
      }
    },
  },
  methods: {
    proximo() {
      if (this.etapa == 3) {
        this.salvarPlanejamento();
      } else {
        this.etapa++;
        if (this.etapa == 2) {
          console.log('adicionais')
          if (this.fnEditar) {
            this.$refs.informacoesAdicionais.links = this.planejamento.links.map(item => {
              return {...item,id: smartId.make()}
            })
          }
          
        }
      }
    },
    
    voltar() {
      this.etapa--;
    },
    async salvarPlanejamento() {
      console.log(1)
      if (!this.$refs.cadastro.validate()) {
        return this.$dialog.notify.error("Formulário com erros", {
          position: "top-right",
          timeout: 5000,
        });
      }
      console.log(2)
      if (!this.$refs.informacoesAdicionais.validate()) {
        return this.$dialog.notify.error("Formulário com erros", {
          position: "top-right",
          timeout: 5000,
        });
      }
      this.carregando = true;
      console.log(3)
      const data = JSON.parse(JSON.stringify(this.planejamento));
      delete data.produtosServidor
      // if (data.marca && data.marca.length > 0) {
      //   data.marca = data.marca.join(",");
      // }
      if(data.skus){
        data.skus = data.skus.join(",");
      }
      if(data.mix_sku_compre_junto){
        data.mix_sku_compre_junto = data.mix_sku_compre_junto.join(",");
      }
      if(data.sku_com_opcao_pdp){
        data.sku_com_opcao_pdp = data.sku_com_opcao_pdp.join(",");
      }

      if(this.planejamento.periodo && this.planejamento.periodo.length > 0){
        data.semana = this.planejamento.periodo
      }

      data.links = this.$refs.informacoesAdicionais.links

      const response = await this.$http().post(
        "/planejamentoEcommerce/salvar",
        {
          data: data,
        }
      );

      if (response.status == 200 && response.data.error == false) {
        this.$dialog.notify.success("Planejamento salvo com sucesso", {
          position: "top-right",
          timeout: 5000,
        });
        this.dialogForm = false;
        this.$emit("filtrar");
      } else {
        const mensagem =
          response.data.mensagem || "Falha ao salvar planejamento";
        this.$dialog.notify.error(mensagem, {
          position: "top-right",
          timeout: 5000,
        });
      }
    },
    async editar(planejamento) {
      // console.log('planejamento',planejamento)
      this.novo();
      this.planejamento = JSON.parse(JSON.stringify(planejamento));
      console.log('planejamento ->',planejamento)
      if(this.planejamento.periodo && this.planejamento.periodo.length > 0){
        this.planejamento.semana = this.planejamento.periodo.map(item => ({mes: item.mes, semana: item.semana}));
      }
      console.log('periodo')
      // this.planejamento.id_marca = this.planejamento.id_marca.split(",");
      console.log(this.planejamento.marca)
      this.planejamento.marca = this.splitPersonalizado(this.planejamento.marca);
      console.log('marca',this.planejamento.marca)
      if(this.planejamento.id_acordo_trade){
        try {
          
          this.planejamento.id_acordo_trade = this.splitPersonalizado(this.planejamento.id_acordo_trade);
        } catch (error) {
          console.log(error)
        }

      }
      console.log('skdjsdksjdk')
      if(this.planejamento.skus ){
        this.planejamento.skus = this.splitPersonalizado(this.planejamento.skus);
        console.log('skue',this.planejamento.skus)
      }else {
        console.log('vazio')
        this.planejamento.skus = [];
      }
      if(this.planejamento.mix_sku_compre_junto){
        this.planejamento.mix_sku_compre_junto = this.splitPersonalizado(this.planejamento.mix_sku_compre_junto);
      }else {
        this.planejamento.mix_sku_compre_junto = [];
      }
      if(this.planejamento.sku_com_opcao_pdp){
        this.planejamento.sku_com_opcao_pdp = this.splitPersonalizado(this.planejamento.sku_com_opcao_pdp);
      }else {
        this.planejamento.sku_com_opcao_pdp = [];
      }
      // console.log(this.planejamento.links,this.$refs.informacoesAdicionais.links)
      // this.$refs.informacoesAdicionais.links = this.planejamento.links.map(item => {
      //   return {...item,id: smartId.make()}
      // })
      setTimeout(() => {
       
        this.$refs.cadastro.getMarcas();
      },100)

    },
    splitPersonalizado(str) {
      str = str + ''
      if (str && str.includes(',')) 
      {
        return str.split(',')
      } else {
        return [str]
      }
    },  
    async replicar(planejamento) {
      this.novo();
      this.planejamento = JSON.parse(JSON.stringify(planejamento));
      delete this.planejamento.id_planejamento;
      delete this.planejamento.id_lote;
      // if(this.planejamento.id_marca){

      //   this.planejamento.id_marca =  this.planejamento.id_marca.split(",");
      // }
      if(this.planejamento.skus){
        this.planejamento.skus = this.splitPersonalizado(this.planejamento.skus);
      }else {
        this.planejamento.skus = [];
      }
      if(this.planejamento.mix_sku_compre_junto){
        this.planejamento.mix_sku_compre_junto = this.splitPersonalizado(this.planejamento.mix_sku_compre_junto);
      }else {
        this.planejamento.mix_sku_compre_junto = [];
      }
      if(this.planejamento.sku_com_opcao_pdp){
        this.planejamento.sku_com_opcao_pdp = this.splitPersonalizado(this.planejamento.sku_com_opcao_pdp);
      }else {
        this.planejamento.sku_com_opcao_pdp = [];
      }
      this.$refs.cadastro.getMarcas();

    },
    novo() {
      this.resetarCadastro();

      this.dialogForm = true;
      this.etapa = 1;
      this.planejamento = {
        mes: this.$moment().format("YYYY-MM"),
        data_inicio: null,
        data_fim: null,
        semana: null,
        observacoes: "",
        device: [],
        vitrine: [],
        bandeira: null,
        id_ativacao: null,
        id_categoria: null,
        id_status_planejamento: 1,
        marca: [],
        sku: [],
        produtosServidor: [],
      };
    },
    async resetarCadastro() {
      // try {
      setTimeout(() => {
        this.$refs.cadastro.resetValidation();  
        this.$refs.cadastro.checaDisponobilidade();
      },50)

        
        // await new Promise((resolve) => setTimeout(resolve, 1000));
        
      // } catch (error) {
        // console.log(error);
        // console.log("Erro ao resetar cadastro");
      // }
    },
  },
};
</script>
