<template>
    <v-container fluid class="mt-0 pt-0 d-flex h-100">
        <v-card class="px-5 py-3 br-15 w-100" elevation="0">
            <v-row>
                <v-col cols="6">
                    <v-toolbar-title class="page-title">PDV</v-toolbar-title>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="2" xl="2">
                    <v-btn
                        class="w-100 new-button"
                        color="primary"
                        :disabled="simulacao.ano < anoLimite"
                        @click="novo()"
                    >
                        <v-icon left>mdi-view-grid-plus</v-icon> Adicionar
                    </v-btn>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="2" xl="2">
                    <v-btn
                        class="new-button w-100"
                        color="primary"
                        @click="exportar()"
                    >
                        <v-icon left>mdi-microsoft-excel</v-icon> Exportar
                    </v-btn>
                </v-col>
                <v-col cols="2">
                    <v-btn
                        class="w-100 new-button"
                        color="primary"
                        @click="filtrar()"
                    >
                        <v-icon left>mdi-filter</v-icon> Filtrar
                    </v-btn>
                </v-col>
            </v-row>
            <hr class="hr-cinza mb-5 mt-3" />

            <v-form ref="formFiltro">
                <v-row>
                    <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                       

                        <v-autocomplete
                            class="input-cinza"
                            
                            label="Fornecedor"
                            v-model="fornecedorProvider.id_fornecedor"
                            :items="lista.fornecedor"
                            :rules="regra.id_fornecedor"
                            v-on:change="fornecedorMarca"
                            outlined
                            dense
                            :menu-props="menuProps"
                        >
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="2">
                        <v-select
                            label="Ano"
                            class="input-cinza"
                            v-model="fornecedorProvider.ano"
                            :items="lista.anos"
                            :rules="regra.ano"
                            :menu-props="menuProps"
                            outlined
                            dense
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="3">
                        <v-autocomplete
                            label="Espaço"
                            v-model="filtro.id_espaco"
                            :items="lista.espaco"
                            class="input-cinza"
                            :menu-props="menuProps"
                            outlined
                            dense
                            multiple
                        >
                            <template v-slot:selection="{ item, index }">
                                <span
                                    v-if="
                                        filtro.id_espaco.length === 1 &&
                                        index === 0
                                    "
                                    >{{ item.text }}</span
                                >
                                <span
                                    v-if="
                                        filtro.id_espaco.length > 1 &&
                                        index === 0
                                    "
                                    class="grey--text caption mr-1"
                                    >{{
                                        filtro.id_espaco.length
                                    }}
                                    selecionados</span
                                >
                            </template>
                        </v-autocomplete>
                    </v-col>

                    <v-col cols="12" sm="12" md="4" lg="4" xl="4" align="end">
                        <div class="total-investido w-100">
                            <div class="texto">Total Investido:</div>
                            <div class="valor">
                                {{ totalInvestido || "R$ 0,00" }}
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-form>
            <div class="involucro">
                <v-data-table
                    class="simulation-table pdv-table table-separator flex-table"
                    v-model="simulacoesSelecionadas"
                    :headers="headers"
                    :items="dados"
                    :options.sync="pagination"
                    :items-per-page="5"
                    :footer-props="{
                        'items-per-page-options': [5, 10, 15, 25, 50, 100, -1],
                        itemsPerPageText: 'Linhas por páginas',
                        ofText: 'de',
                        pageText: '{0}-{1} de {2}',
                    }"
                    :header-props="{ sortIcon: 'mdi-chevron-down' }"
                    multi-sort
                    show-select
                    item-key="id_simulacao"
                >
                    <!-- <template
                        v-slot:[`item.data-table-select`]="{
                            
                            isSelected,
                            select
                        }"
                    >
                        <v-simple-checkbox
                            :ripple="false"
                            :value="isSelected"
                            :disabled="true "
                            @input="select($event)"

                    
                        ></v-simple-checkbox>
                    </template> -->

                    <template v-slot:header="{}">
                        <thead class="header0">
                            <tr>
                                <th
                                    v-for="(h, i) in headersGroup0"
                                    :key="i"
                                    :colspan="h.colspan"
                                    :class="h.class"
                                    style="height: 45px"
                                >
                                    <v-icon :color="h.color" class="mx-1" size="18">
                                        {{ h.icon }}
                                    </v-icon>
                                    {{ h.text }}
                                </th>
                            </tr>
                            <tr>
                                <th
                                    v-for="(h, i) in headersGroup"
                                    :key="i"
                                    :colspan="h.colspan"
                                    :class="h.class"
                                    style="height: 45px"
                                >
                                    {{ h.text }}
                                </th>
                            </tr>
                        </thead>
                    </template>
                    <template v-slot:[`item.botao`]="{ item }">
                        <v-icon
                            v-if="item.ano >= anoLimite"
                            small
                            class="mr-2"
                            color="orange"
                            @click="editarSimulacao(item)"
                            title="Editar"
                        >
                            mdi-circle-edit-outline
                        </v-icon>
                    </template>
                    <template
                        v-slot:[`item.espaco`]="{ item }"
                        class="quebra-linha"
                    >
                        <div
                            style="cursor: default"
                            class="quebra-linha"
                            :title="item.espaco"
                        >
                            {{ item.espaco }}
                        </div>
                    </template>
                    <template v-slot:[`item.obs`]="{ item }">
                        <div
                            @click="
                                !statusAcesso(
                                    item.id_status_simulacao,
                                    'aprovar_reprovar'
                                )
                                    ? editarTipo(item, 'obs', 'Observação')
                                    : false
                            "
                            style="cursor: pointer"
                            class="cell-editable"
                        >
                            <div v-if="item.obs">
                                <v-icon color="#ccc" class="m-0" size="15" left
                                    >mdi-eye</v-icon
                                >
                                Visualizar
                            </div>
                            <div v-else>Adicionar</div>
                        </div>
                    </template>
                    <template v-slot:[`item.marca`]="{ item }">
                        <div>
                            <p
                                class="my-1"
                                v-for="marca in item.marca"
                                :key="marca"
                            >
                                {{ marca }}
                            </p>
                        </div>
                    </template>
                    <template
                        v-for="index in 12"
                        v-slot:[`item.filiais_${index}`]="{ item }"
                    >
                        <div :key="index">
                            <v-tooltip
                                bottom
                                :disabled="
                                    !comparaVersoes(
                                        item['va_filiais_' + index],
                                        item['filiais_' + index]
                                    )
                                "
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-bind="attrs"
                                        v-on="on"
                                        v-if="!item.organico"
                                        :value="item['filiais_' + index]"
                                        :class="{
                                            diff: comparaVersoes(
                                                item['va_filiais_' + index],
                                                item['filiais_' + index]
                                            ),
                                            'input-cinza text-center': true,
                                        }"
                                        v-mask="'####'"
                                        @blur="
                                            (e) =>
                                                updateValor(
                                                    item,
                                                    'filiais_' + index,
                                                    e.target.value
                                                )
                                        "
                                        @focus="
                                            (e) => (valorEdicao = e.target.value)
                                        "
                                        outlined
                                        dense
                                        hide-details
                                    >
                                    </v-text-field>
                                </template>
                                <span class="text-center"
                                    >Valor anterior:
                                    {{ item["va_filiais_" + index] }}
                                </span>
                            </v-tooltip>
                            <div v-if="item.organico" class="cell-editable">
                                {{ item["filiais_" + index] }}
                            </div>
                            <!-- {{item['filiais_' + index]}} - {{index}} {{item.id_simulacao}} -->
                        </div>
                    </template>
                    <template
                        v-for="index in 12"
                        v-slot:[`item.desconto_${index}`]="{ item }"
                        ><div :key="index">
                            <v-tooltip
                                bottom
                                :disabled="
                                    !comparaVersoes(
                                        item['va_desconto_' + index],
                                        item['desconto_' + index]
                                    )
                                "
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-bind="attrs"
                                        v-on="on"
                                        :value="item['desconto_' + index]"
                                        :class="{
                                            diff: comparaVersoes(
                                                item['va_desconto_' + index],
                                                item['desconto_' + index]
                                            ),
                                            'input-cinza text-center': true,
                                        }"
                                        v-mask="'###'"
                                        suffix="%"
                                        @blur="
                                            (e) =>
                                                updateValor(
                                                    item,
                                                    'desconto_' + index,
                                                    e.target.value
                                                )
                                        "
                                        @focus="
                                            (e) => (valorEdicao = e.target.value)
                                        "
                                        outlined
                                        dense
                                        hide-details
                                    >
                                    </v-text-field>
                                </template>
                                <span class="text-center"
                                    >Valor anterior:
                                    {{ item["va_desconto_" + index] }}
                                </span>
                            </v-tooltip>
                        </div>
                    </template>
                </v-data-table>
            </div>
            <v-btn
                v-if="simulacoesSelecionadas.length > 0"
                class="new-button px-4"
                color="error"
                @click="dialogoExcluir = true"
                >Excluir simulações selecionadas</v-btn
            >
        </v-card>

        <v-dialog v-model="dialogo" width="90%">
            <v-card class="radius-10">
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text"
                >
                    <v-toolbar-title class="px-1"
                        >{{ itemEdicao ? "Editar" : "Adicionar" }} Espaço /
                        Cluster</v-toolbar-title
                    >
                    <v-spacer></v-spacer>

                    <v-btn
                        fab
                        elevation="0"
                        x-small
                        color="#fff"
                        @click="dialogo = false"
                    >
                        <v-icon color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text class="px-5 py-5">
                    <v-form ref="form">
                        <v-row>
                            <v-col
                                cols="12"
                                sm="12"
                                md="6"
                                lg="3"
                                xl="3"
                                class="pb-0"
                            >
                                <v-autocomplete
                                    label="Espaço"
                                    v-model="simulacao.id_espaco"
                                    :items="lista.espaco"
                                    :rules="regra.id_espaco"
                                    v-on:change="espacoCluster"
                                    class="input-cinza"
                                    :menu-props="menuProps"
                                    outlined
                                    dense
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="6"
                                lg="3"
                                xl="3"
                                v-if="simulacao.id_espaco"
                                class="pb-0"
                            >
                                <v-select
                                    label="Cluster"
                                    class="input-cinza"
                                    v-model="simulacao.id_cluster"
                                    :items="lista.cluster"
                                    :rules="regra.id_cluster"
                                    v-on:change="selecionaCluster"
                                    :return-object="false"
                                    :menu-props="menuProps"
                                    outlined
                                    dense
                                >
                                    <template
                                        v-slot:selection="{ item, index }"
                                    >
                                        <span
                                            v-if="
                                                simulacao.id_cluster.length ===
                                                    1 && index === 0
                                            "
                                            >{{ item.text }}</span
                                        >
                                        <span
                                            v-if="
                                                simulacao.id_cluster.length >
                                                    1 && index === 0
                                            "
                                            class="grey--text caption mr-1"
                                            >{{
                                                simulacao.id_cluster.length
                                            }}
                                            selecionados</span
                                        >
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="6"
                                lg="3"
                                xl="3"
                                class="pb-0"
                            >
                                <v-select
                                    label="Bandeira"
                                    class="input-cinza"
                                    v-model="simulacao.bandeira"
                                    :items="lista.bandeira"
                                    :rules="regra.bandeira"
                                    :menu-props="menuProps"
                                    outlined
                                    multiple
                                    dense
                                >
                                </v-select>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="6"
                                lg="3"
                                xl="3"
                                class="pb-0"
                            >
                                <v-autocomplete
                                    label="Marca"
                                    class="input-cinza"
                                    v-model="simulacao.marca"
                                    :items="lista.marca"
                                    :menu-props="menuProps"
                                    outlined
                                    multiple
                                    dense
                                >
                                    <template
                                        v-slot:selection="{ item, index }"
                                    >
                                        <span
                                            v-if="
                                                simulacao.marca.length === 1 &&
                                                index === 0
                                            "
                                            >{{ item }}</span
                                        >
                                        <span
                                            v-if="
                                                simulacao.marca.length > 1 &&
                                                index === 0
                                            "
                                            class="grey--text caption mr-1"
                                            >{{
                                                simulacao.marca.length
                                            }}
                                            selecionados</span
                                        >
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="6"
                                lg="3"
                                xl="3"
                                v-if="showCheckOut"
                                class="pb-0"
                            >
                                <v-autocomplete
                                    label="Check-Out"
                                    class="input-cinza"
                                    v-model="simulacao.check_out"
                                    :items="lista.check_out"
                                    :menu-props="menuProps"
                                    clearable
                                    outlined
                                    dense
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="12"
                                md="6"
                                lg="3"
                                xl="3"
                                v-if="lista.meses.length"
                                class="pb-0"
                            >
                                <v-select
                                    label="Período da ação"
                                    v-model="simulacao.meses"
                                    :items="lista.meses"
                                    class="input-cinza"
                                    :menu-props="menuProps"
                                    multiple
                                    outlined
                                    dense
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item
                                            ripple
                                            @mousedown.prevent
                                            @click="selecionarTodosMeses"
                                        >
                                            <v-list-item-action>
                                                <v-icon>
                                                    {{
                                                        simulacao.meses
                                                            .length >= 12
                                                            ? "mdi-close-box"
                                                            : simulacao.meses
                                                                  .length === 0
                                                            ? "mdi-checkbox-blank-outline"
                                                            : "mdi-minus-box"
                                                    }}
                                                </v-icon>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{
                                                        simulacao.meses
                                                            .length >= 12
                                                            ? "Desmarcar"
                                                            : "Selecionar"
                                                    }}
                                                    todos os meses
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                    <template
                                        v-slot:selection="{ item, index }"
                                    >
                                        <span
                                            v-if="
                                                simulacao.meses.length === 1 &&
                                                index === 0
                                            "
                                            >{{ item.nome }}</span
                                        >
                                        <span
                                            v-if="
                                                simulacao.meses.length > 1 &&
                                                index === 0
                                            "
                                            class="grey--text caption mr-1"
                                            >{{
                                                simulacao.meses.length
                                            }}
                                            selecionados</span
                                        >
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                        <div class="d-flex align-center mt-1 mb-1">
                            <h2 style="font-weight: 500">
                                Observações
                            </h2>
                            <v-divider class="mx-5" />
                        </div>
                        <v-row>
                            <v-col
                                cols="12"
                                sm="12"
                                class="py-1"
                            >
                                <v-textarea
                                    v-model="obs"
                                    placeholder="Digite a observação…"
                                    hide-details
                                    rows="2"
                                    outlined
                                    class="input-cinza mb-n2"
                                    dense
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>        
                        <div v-if="showMeses">
                            <div class="d-flex align-center mt-1 mb-1">
                                <h2 style="font-weight: 500">
                                    Personalizar período
                                </h2>
                                <v-divider class="mx-5" />
                                <div class="d-flex align-center">
                                    <v-checkbox
                                        color="primary"
                                        v-model="replicarValores"
                                    ></v-checkbox>
                                    <span
                                        >Replicar informações para todos os
                                        meses</span
                                    >
                                </div>
                            </div>

                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="12"
                                    md="6"
                                    lg="3"
                                    xl="3"
                                    class="py-1"
                                    v-for="(mes, i) in Object.keys(valores)"
                                    :key="i"
                                >
                                    <MesBox
                                        :esconderLojas="espacoOrganico"
                                        :esconderDesconto="
                                            lista.usuarioTipo !== 'rd'
                                        "
                                        :mes="mes"
                                        :replicarValores="replicarValores"
                                        :active="
                                            simulacao.meses.includes(i + 1)
                                        "
                                        :icon="
                                            4 <= i && i <= 8
                                                ? 'inverno'
                                                : 'verao'
                                        "
                                        :regra="{
                                            desconto: regra.desconto,
                                            filiais: regra.filiais,
                                        }"
                                        v-model="valores"
                                    />
                                </v-col>
                            </v-row>
                        </div>
                        <!-- fim de meses -->
                    </v-form>
                    <div class="d-flex justify-end w-100">
                        <v-btn
                            v-if="itemEdicao"
                            color="red"
                            dark
                            class="pr-4 mr-4 new-button"
                            @click="confirmExcluir(itemEdicao)"
                        >
                            <v-icon left>mdi-close-circle</v-icon> Excluir
                            simulação
                        </v-btn>
                        <v-btn
                            color="primary"
                            class="pr-4 new-button"
                            @click="confirmSalvar()"
                        >
                            <v-icon left>mdi-check-circle</v-icon>
                            Salvar
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="editar.dialogo" width="600">
            <v-card class="radius-10">
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text"
                >
                    <v-toolbar-title>
                        {{
                            editar.tipo === "obs" && editar.valor === null
                                ? "Adicionar"
                                : "Editar"
                        }}
                        {{ editar.titulo }}</v-toolbar-title
                    >
                    <v-spacer></v-spacer>

                    <v-btn
                        fab
                        elevation="0"
                        x-small
                        color="#fff"
                        @click="editar.dialogo = false"
                    >
                        <v-icon color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="px-5 py-5">
                    <v-form ref="formEditar">
                        <v-row no-gutters>
                            <v-col cols="12" v-if="editar.tipo == 'obs'">
                                <v-textarea
                                    v-model="editar.valor"
                                    placeholder="Digite a observação…"
                                    hide-details
                                    rows="8"
                                    outlined
                                    class="input-cinza mb-n2"
                                    dense
                                >
                                </v-textarea>
                            </v-col>
                            <v-col
                                cols="12"
                                class="px-2"
                                v-if="editar.tipo.split('_')[0] == 'desconto'"
                            >
                                <v-text-field
                                    :label="editar.titulo"
                                    v-model="editar.valor"
                                    :rules="regra.desconto"
                                    v-mask="'###'"
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                class="px-2"
                                v-if="editar.tipo.split('_')[0] == 'filiais'"
                            >
                                <v-text-field
                                    v-model="editar.valor"
                                    :rules="regra.filiais"
                                    v-mask="'####'"
                                    outlined
                                    dense
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>

                    <div class="d-flex justify-end w-100 mt-5">
                        <v-btn
                            v-if="editar.tipo == 'obs'"
                            color="red"
                            dark
                            class="mr-4"
                            style="font-size: 1.2rem"
                            @click="
                                editar.valor = null;
                                editarValor();
                                editar.dialogo = false;
                            "
                        >
                            <v-icon left>mdi-close-circle</v-icon> Apagar
                            observação
                        </v-btn>
                        <v-btn
                            color="primary"
                            class="pr-4"
                            style="font-size: 1.2rem"
                            @click="editarValor()"
                        >
                            <v-icon left>mdi-check-circle</v-icon>
                            Salvar
                        </v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="confirm.dialogo" width="600">
            <v-card>
                <v-card-title class="subtitle-1 primary white--text">
                    {{ confirm.titulo }}
                </v-card-title>
                <v-card-text class="mt-4">
                    {{ confirm.texto }}
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="pr-4"
                        @click="confirm.dialogo = false"
                    >
                        <v-icon left>mdi-close</v-icon> Fechar
                    </v-btn>
                    <v-btn
                        :color="confirm.color"
                        class="pr-4"
                        @click="confirm.funcao"
                    >
                        <v-icon left>mdi-close</v-icon> {{ confirm.botao }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogoExcluir" content-class="br-15" width="600">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="error white--text fix-flex"
                >
                    <v-toolbar-title>
                        Excluir simulações selecionadas
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogoExcluir = false"
                    >
                        <v-icon size="18px" color="error">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-4">
                    Deseja realmente excluir as simulações selecionadas?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        class="pr-4 mr-2 mb-2 new-button"
                        @click="dialogoExcluir = false"
                    >
                        <v-icon left>mdi-close-circle</v-icon> Não, não quero
                        excluir
                    </v-btn>
                    <v-btn
                        class="pr-4 mb-2 new-button"
                        color="primary"
                        @click="excluirMultipos"
                    >
                        <v-icon left>mdi-check-circle</v-icon> Sim, quero
                        excluir
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Dialogo detalhes salvar simulação -->

        <v-dialog content-class="br-15" v-model="dialogSalvarSimulacao" width="80%" scrollable>
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title>
                        Salvar simulação - Resumo
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogSalvarSimulacao = false"
                    >
                        <v-icon size="18px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="pa-5">

                    <template>
                    <v-data-table
                        :headers="[
                            {
                                text: 'Mês',
                                align: 'center',
                                sortable: false,
                                value: 'mes',
                                class: 'min-width-100 th-separador'
                            },

                            { text: 'Filiais', value: 'filiais', class: 'min-width-100 th-separador', align: 'center'},

                            { text: 'Valor', value: 'valor', class: 'min-width-100 th-separador', align: 'center' },
                            { text: 'Desconto (%)', value: 'desconto_percentual', class: 'min-width-100 th-separador', align: 'center'},
                            { text: 'Desconto (R$)', value: 'desconto', class: 'min-width-100 th-separador', align: 'center' },
                            { text: 'Investimento', value: 'investimento', class: 'min-width-100', align: 'center'}
                            
                        ]"
                        :items="detalhesSimulacaoFormatada"
                        class="elevation-1 tabela "
                        hide-default-footer
                        :items-per-page="15"
                        
                    ></v-data-table>
                    </template>
                    <p class="mt-5 mb-0">
                        Deseja salvar a simulação?  

                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="new-button ml-auto mb-2 mr-2 px-3"
                        color="error"
                        @click="dialogSalvarSimulacao = false"
                    >
                        <v-icon class="mr-3">mdi-check-circle</v-icon>
                        Não salvar
                    </v-btn>
                    <v-btn
                        class="new-button mb-2 px-3"
                        color="primary"
                        @click="salvar()"
                    >
                        <v-icon class="mr-3">mdi-check-circle</v-icon>
                        Sim, salvar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-snackbar
            v-model="alerta"
            :color="alertaColor"
            :timeout="alertaTimeout"
            top
            style="z-index: 400"
        >
            <v-alert :type="alertaColor">
                {{ alertaTexto }}
            </v-alert>
            <v-btn dark text class="float-right" @click="alerta = false">
                Fechar
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
import MesBox from "../_components/mes_box.vue";

export default {
    name: 'simuladorListaPdv',
    components: {
        MesBox,
    },
    inject: ["fornecedorProvider"],
    data() {
        return {
            lista: {
                meses: [],
            },
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: "select-outline",
                transition: "slide-y-transition",
            },
            obs: '',
            dialogSalvarSimulacao: false,
            detalhesSalvarSimulacao: [
        
            ],
            simulacoesSelecionadas: [],
            itemEdicao: null,
            valorEdicao: null,
            espacoOrganico: false,
            replicarValores: false,
            dialogoExcluir: false,
            filtro: {
                id_espaco: [],
            },
            valores: {
                janeiro: {},
                fevereiro: {},
                março: {},
                abril: {},
                maio: {},
                junho: {},
                julho: {},
                agosto: {},
                setembro: {},
                outubro: {},
                novembro: {},
                dezembro: {},
            },

            meses: [
                "Janeiro",
                "Fevereiro",
                "Março",
                "Abril",
                "Maio",
                "Junho",
                "Julho",
                "Agosto",
                "Setembro",
                "Outubro",
                "Novembro",
                "Dezembro",
            ],
            headersGroup0: [
                {
                    text: null,
                    colspan: 8,
                    class: "valorTotal",
                },
            ],
            headersGroup: [
                {
                    text: null,
                    colspan: 8,
                    class: "",
                },
            ],
            headers: [
                {
                    text: "",
                    value: "botao",
                    align: "center",
                    sortable: false,
                    class: "th-separador",
                },
                {
                    text: "Status",
                    value: "status",
                    align: "center",
                    sortable: false,
                    class: "th-separador min-width-100",
                },
                {
                    text: "Marca",
                    value: "marca",
                    align: "center",
                    class: "th-separador min-width-100",
                },
                {
                    text: "Espaço Macro",
                    value: "espaco_macro",
                    align: "center",
                    class: "th-separador min-width-100",
                },
                {
                    text: "Espaço",
                    value: "espaco",
                    align: "center",
                    class: "sticky-col first-col background-primary th-separador min-width-100",
                },
                {
                    text: "Bandeira",
                    value: "bandeira",
                    align: "center",
                    class: "sticky-col second-col background-primary th-separador min-width-100",
                },
                {
                    text: "Cluster",
                    value: "cluster",
                    align: "center",
                    class: "sticky-col third-col background-primary th-separador",
                },
                {
                    text: "Modelo de Check-Out",
                    value: "check_out",
                    align: "center",
                    class: "th-separador min-width-100",
                },
                {
                    text: "Observação",
                    value: "obs",
                    align: "center",
                    class: "th-separador min-width-100",
                },
                { text: "Nº de Meses", value: "meses", align: "center" },
            ],
            dados: [],
            simulacao: {
                id_fornecedor: this.fornecedorProvider.id_fornecedor,
                ano: this.fornecedorProvider.ano,
                meses: [],
                id_cluster: [],
                bandeira: [],
                marca: [],
            },
            item: {},
            pagination: {},
            total: null,
            carregando: false,
            dialogo: false,
            alerta: false,
            alertaTexto: "",
            alertaColor: "error",
            alertaTimeout: 3000,
            regra: {
                ano: [(v) => !!v || "O campo ano é obrigatório."],
                id_fornecedor: [
                    (v) => !!v || "O campo fornecedor é obrigatório.",
                ],
                id_espaco: [(v) => !!v || "O campo espaço é obrigatório."],
                id_cluster: [
                    (v) => v.length > 0 || "Selecione pelo menos um cluster.",
                ],
                bandeira: [
                    (v) => v.length > 0 || "O campo bandeira é obrigatório.",
                ],
                desconto: [
                    (v) =>
                        (!!v && v >= 0 && v <= 100) ||
                        "O desconto deve ser um numero inteiro ente 0 e 100.",
                ],
                filiais: [
                    (v) =>
                        (!!v && v >= 1) ||
                        "O número de lojas deve ser um numero inteiro e maior que 0.",
                ],
            },
            confirm: {
                dialogo: false,
                titulo: null,
                texto: null,
                funcao: null,
                color: null,
                botao: null,
            },
            editar: {
                dialogo: false,
                valor: null,
                titulo: null,
                tipo: "",
            },
            totalInvestido: null,
            showCheckOut: false,
        };
    },
    watch: {
        fornecedorProvider: {
            handler(val) {
                this.simulacao.id_fornecedor = val.id_fornecedor;
                this.simulacao.ano = val.ano;
            },
            deep: true,
        },
    },

    computed: {

        detalhesSimulacaoFormatada(){
            const lista =  this.detalhesSalvarSimulacao
            .filter(item => item.filiais > 0)
            .map(item => {
                return {
                    mes: this.meses[item.mes - 1],
                    filiais: item.filiais,
                    desconto_percentual: `${item.desconto_percentual}%`,
                    desconto: item.desconto.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    }),
                    valor: item.valor.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    }),
                    investimento: item.investimento.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    }),
                }
            })

            // add total no final
            lista.push({
                mes: 'Total',
                filiais: lista.reduce((a, b) => a + Number(b.filiais), 0),
                valor: this.detalhesSalvarSimulacao.reduce((a, b) => a + b.valor, 0).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                }),
                desconto: this.detalhesSalvarSimulacao.reduce((a, b) => a + b.desconto, 0).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                }),

                investimento: this.detalhesSalvarSimulacao.reduce((a, b) => a + b.investimento, 0).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                }),
            })
            return lista;

        },
        anoProximo() {
            return new Date().getFullYear();
        },
        anoLimite() {
            return new Date().getFullYear();
        },
        showMeses() {
            if (this.lista.usuarioTipo !== "rd" && this.espacoOrganico)
                return false;
            return true;
        },
    },
    methods: {
        async updateValor(item, campo, valor) {
            if (this.valorEdicao == valor) {
                return;
            }

            const [tipo, mes] = campo.split("_");
            if (tipo == "desconto" && valor > 100) {
                this.mostrarAlerta({
                    texto: "O desconto deve ser um numero inteiro ente 0 e 100.",
                    color: "error",
                    timeout: 3000,
                });
                item[campo] = this.valorEdicao;
                return;
            }
            if (tipo == "filiais" && valor < 1) {
                this.mostrarAlerta({
                    texto: "O número de lojas deve ser um numero inteiro igual ou maior que 50.",
                    color: "error",
                    timeout: 3000,
                });
                item[campo] = this.valorEdicao;
                return;
            }
            var data = {
                id_simulacao: item.id_simulacao,
                tipo: campo,
                obj: {},
            };

            data.obj[campo] = valor;

            var response = await this.$http().post("/simulacao/pdv/editar", {
                data: data,
            });

            this.carregando = false;

            if (response.data.sucesso) {
                this.filtrar();
                //   this.mostrarAlerta({
                //   texto:
                //     "Valores atualizados com sucesso.",
                //   color: "success",
                //   timeout: 3000,
                // });
                // this.editar.dialogo = false;
            } else {
                item[campo] = this.valorEdicao;
                this.alerta = true;
                this.alertaColor = "error";
                this.alertaTimeout = -1;
                this.alertaTexto = response.data.msg;
            }
        },
        selecionarTodosMeses() {
            if (this.simulacao.meses.length < 12) {
                this.simulacao.meses = Array.from(Array(12).keys()).map(
                    (i) => i + 1
                );
            } else {
                this.simulacao.meses = [];
            }
        },
        viewMarca(item) {
            const html = `
								<ul>
										<li>${item.marca.join("</li><li>")}</li>
								</ul>
						`;

            this.$dialog.confirm({
                text: html,
                title: "Marcas",
                actions: [
                    {
                        text: "FECHAR",
                        color: "teal",
                    },
                ],
                color: "teal lighten-2 text--white",
                icon: false,
                dark: true,
            });
        },
        statusAcesso(id_status_simulacao, acesso) {
            if (this.lista.status && id_status_simulacao) {
                var status = this.lista.status.find(
                    (e) => e.id_status_simulacao == id_status_simulacao
                );

                if (
                    id_status_simulacao == 2 &&
                    this.lista.usuarioTipo == "rd"
                ) {
                    return false;
                } else if (
                    id_status_simulacao == 3 &&
                    this.lista.usuarioTipo == "rd"
                ) {
                    return false;
                } else {
                    return status[acesso];
                }
            } else {
                return false;
            }
        },
        async filtrar() {
            console.log('inicio filtrar')
            if (!this.simulacao.id_fornecedor) return;
            if (!this.$refs.formFiltro.validate()) return;
            console.log('avancando filtro')
            this.carregando = true;

            this.pagination.page = 1;

            var data = {};

            data.simulacao = this.simulacao;
            data.pagination = this.pagination;
            data.id_espaco = this.filtro.id_espaco;

            var response = await this.$http().post("/simulacao/pdv/lista", {
                data: data,
            });

            this.dados = response.data;

            //   this.headersGroup0[0].text =
            //     "Total Investido PDV: " +
            this.totalInvestido = this.dados
                .reduce((a, b) => a + b.valor_us * 1, 0)
                .toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                });

            this.carregando = false;
        },
        async exportar() {
            if (!this.$refs.formFiltro.validate()) return;

            this.carregando = true;

            var data = {};

            data.simulacao = this.simulacao;

            var response = await this.$http().post("/simulacao/pdv/exportar", {
                data: data,
            });

            window.location = this.$http("baseURL") + response.data.url;

            this.carregando = false;
        },
        async novo() {
            this.itemEdicao = null;
            this.simulacao.id_simulacao = null;
            if (!this.$refs.formFiltro.validate()) return;

            try {
                this.$refs.form.resetValidation();
            } catch (error) {
                //console.log(error)
            }

            this.simulacao.id_espaco = null;
            this.simulacao.id_cluster = [];
            this.lista.cluster = [];
            this.simulacao.bandeira = [];
            this.simulacao.marca = [];
            this.simulacao.check_out = null;
            this.simulacao.meses = [];
            this.lista.meses = [];
            this.espacoOrganico = true;
            this.carregando = true;
            this.replicarValores = false;
            var data = {
                id_fornecedor: this.simulacao.id_fornecedor,
                ano: this.simulacao.ano,
                usuarioTipo: this.lista.usuarioTipo,
            };
            this.dialogo = true;
            for (let i = 1; i <= 12; i++) {
                const mes = this.meses[i - 1].toLowerCase();
                this.valores[mes]["lojas"] = 0;
                this.valores[mes]["desconto"] = 0;

                //  this.valores[mes]["lojas"] = 100
            }
            this.obs = ''
            await this.carregarDescontos();
            this.carregando = false;
        },
        mostrarAlerta({ color, timeout, texto }) {
            this.alerta = true;
            this.alertaColor = color || "error";
            this.alertaTimeout = timeout || -1;
            this.alertaTexto = texto || "";
        },
        async fornecedorMarca() {
            if (!this.$refs.formFiltro.validate()) return;

            this.carregando = true;

            var data = this.simulacao;
            data.id_fornecedor = this.fornecedorProvider.id_fornecedor

            var response = await this.$http().post(
                "/simulacao/pdv/fornecedorMarca",
                {
                    data: data,
                }
            );

            this.lista.marca = response.data;

            this.carregando = false;

            this.filtrar();
        },
        async espacoCluster() {
            this.carregando = true;

            const espacoItem = this.lista.espaco.find(
                (e) => e.value == this.simulacao.id_espaco
            );

            this.espacoOrganico = espacoItem.organico === 1;
            if(espacoItem.espaco_macro == "Cubo"){
                this.espacoOrganico = true;
            }
            var data = this.simulacao;

            var response = await this.$http().post(
                "/simulacao/pdv/espacoCluster",
                {
                    data: data,
                }
            )

            response.data = response.data.filter(data => {
                return data.id_cluster != 7
            });

            const _categorias = [
                ...new Set(response.data.map((e) => e.categoria)),
            ];
            var _arrayCluster = [];

            for (const cat of _categorias) {
                let _filter = response.data.filter((e) => e.categoria == cat);
                let _text = [...new Set(_filter.map((e) => e.text))].join("/");
                let _value = [...new Set(_filter.map((e) => e.value))];

                _arrayCluster.push({
                    text: _text,
                    value: _value,
                    ids: _value,
                });
            }

            //console.log(_arrayCluster);

            this.lista.cluster = _arrayCluster;
            this.simulacao.meses = [];
            this.lista.meses = [];
            const _espacoMacro = this.lista.espaco.find(
                (e) => e.value == this.simulacao.id_espaco
            ).espaco_macro;

            this.showCheckOut = _espacoMacro.toUpperCase() == "CHECK-OUT";

            console.log('passou aqui')

            for (let m = 1; m <= 12; m++) {
                this.lista.meses.push({
                    value: m,
                    text:
                        this.meses[m - 1] +
                        " - " +
                        (4 <= m && m <= 8 ? "Inverno" : "Verão"),
                    nome: this.meses[m - 1],
                });
            }

            console.log(this.lista.meses)

            this.simulacao.id_cluster = [];

            this.carregando = false;
        },
        selecionaCluster() {
            /* var cluster = this.lista.cluster.find(
				(e) => e.id_cluster == this.simulacao.id_cluster[0]
			);

			for (const c of this.lista.cluster) {
				if (
					this.simulacao.id_cluster.length == 0 ||
					c.categoria == cluster.categoria
				) {
					c.disabled = false;
				} else {
					c.disabled = true;
				}
			} */
        },
        async confirmSalvar() {
            if (!this.$refs.form.validate()) return;
            const _response = await this.salvar(true);

            if (!_response.data.sucesso) {
                this.$dialog.notify.error(
                    "Falha ao calcular a simulação, por favor, tente novamente.",
                    {
                        position: "top-right",
                        timeout: 5000,
                    }
                );
                return;
            }

            // let _valor = this.$helpers.roundDecimal(
            //     _response.data.result.total,
            //     2
            // );
            // const _text = `
			// 	Deseja salvar a simulação no valor total de R$ ${_valor.toLocaleString()} de investimento?  
			// `;

            // const _dialogConfirm = await this.$dialog.confirm({
            //     text: _text,
            //     title: "Salvar simulação",
            //     actions: [
            //         { text: "NÃO", color: "red" },
            //         { text: "SIM", color: "teal" },
            //     ],

            //     color: "teal lighten-2",
            // });
            this.detalhesSalvarSimulacao = _response.data.detalhes;
            this.dialogSalvarSimulacao = true;


            // const _confirm = await _dialogConfirm.wait();

            // if (_confirm == "SIM") this.salvar();
        },
        async salvar(flag_simulacao = false) {
            if (!this.$refs.form.validate()) return;
            this.dialogSalvarSimulacao = false;

            this.carregando = true;

            var data = this.simulacao;

            data["valoresMeses"] = this.simulacao.meses.map((item) => {
                const mes = this.meses[item - 1].toLowerCase();

                return {
                    mes: item,
                    lojas: this.valores[mes]["lojas"],
                    desconto: this.valores[mes]["desconto"],
                };
            });
            // console.log(this.itemEdicao)
            // console.log(data)
            if (this.itemEdicao) {
                data["id_simulacao"] = this.itemEdicao.id_simulacao;
            }

            data["flag_simulacao"] = flag_simulacao;
            data['obs'] = this.obs

            var response = await this.$http().post("/simulacao/pdv/salvar", {
                data: data,
            });

            this.carregando = false;

            if (flag_simulacao) return response;

            if (response.data.sucesso) {
                this.filtrar();
                this.dialogo = false;
            } else {
                this.alerta = true;
                this.alertaColor = "error";
                this.alertaTimeout = -1;
                this.alertaTexto = response.data.msg;
            }
        },
        confirmExcluir(item) {
            this.item = { ...item };

            this.confirm.titulo = "Excluir Linha";
            this.confirm.texto =
                "Deseja realmente excluir a linha da simulação?";
            this.confirm.color = "error";
            this.confirm.botao = "Excluir";
            this.confirm.funcao = this.excluir;
            this.confirm.dialogo = true;
        },
        async excluir() {
            this.carregando = true;

            this.confirm.dialogo = false;

            var data = this.item;

            await this.$http().post("/simulacao/pdv/excluir", { data: data });

            this.carregando = false;
            this.dialogo = false;
            this.filtrar();
        },
        async excluirMultipos() {
            this.carregando = true;

            var data = {};
            data.id_simulacao = this.simulacoesSelecionadas.map(
                (item) => item.id_simulacao
            );

            await this.$http().post("/simulacao/pdv/excluir", { data: data });

            this.carregando = false;
            this.dialogoExcluir = false;
            this.filtrar();
        },
        async editarSimulacao(item) {
            this.itemEdicao = item;

            if (!this.$refs.formFiltro.validate()) return;

            try {
                this.$refs.form.resetValidation();
            } catch (error) {
                //console.log(error)
            }

            this.simulacao.id_espaco = item.id_espaco;

            await this.espacoCluster();

            this.simulacao.id_cluster = item.cluster_id;
            //   this.lista.cluster = [item.cluster];
            this.simulacao.bandeira = item.bandeira
                .split(",")
                .map((s) => s.trim());
            this.simulacao.marca = item.marca;
            this.simulacao.check_out = item.check_out;
            this.simulacao.meses = [];
            this.lista.meses = [];
            this.replicarValores = false;
            this.obs = item.obs

            this.carregando = true;
            var data = {
                id_fornecedor: this.simulacao.id_fornecedor,
                ano: this.simulacao.ano,
                usuarioTipo: this.lista.usuarioTipo,
            };
            if (this.lista.meses.length < 12) {
                for (let m = 1; m <= 12; m++) {
                    this.lista.meses.push({
                        value: m,
                        text:
                            this.meses[m - 1] +
                            " - " +
                            (4 <= m && m <= 8 ? "Inverno" : "Verão"),
                        nome: this.meses[m - 1],
                    });
                }
            }
            //   var lista = (
            //     await this.$http().post("/simulacao/aprovacao/bloquearAcessos", {
            //       data: data,
            //     })
            //   ).data;

            //   if (
            //     lista.campanha.length > 0 ||
            //     lista.encommerce.length > 0 ||
            //     lista.pdv.length > 0 ||
            //     lista.tabloide.length > 0
            //   ) {
            //     this.alerta = true;
            //     this.alertaColor = "error";
            //     this.alertaTimeout = -1;
            //     this.alertaTexto =
            //       "Existe simulações aprovadas pelo fornecedor para este ano";
            //   } else {
            //     this.dialogo = true;
            //   }
            this.dialogo = true;
            this.carregando = false;

            // Habilita os meses para edição
            for (let i = 1; i <= 12; i++) {
                const filiais = this.itemEdicao[`filiais_${i}`] || 0;
                const desconto = this.itemEdicao[`desconto_${i}`] || 0;
                if (filiais > 0) {
                    this.simulacao.meses.push(i);
                }

                const mes = this.meses[i - 1].toLowerCase();
                this.valores[mes]["lojas"] = filiais;
                this.valores[mes]["desconto"] = desconto;

                //  this.valores[mes]["lojas"] = 100
            }
        },
        editarTipo(item, tipo, titulo) {
            if (tipo.split("_")[0] == "filiais" && item.organico) return;

            this.editar.dialogo = true;

            setTimeout(() => {
                this.editar.tipo = tipo;
                this.editar.titulo = titulo;

                setTimeout(() => {
                    this.editar.valor = item[tipo];
                }, 100);
            }, 100);

            this.item = { ...item };
        },
        async carregarDescontos() {
            const response = await this.$http().post(
                "/simulacao/pdv/listarDesconto",
                {
                    data: {
                        id_fornecedor: this.simulacao.id_fornecedor || 1,
                        ano: this.simulacao.ano || 2022,
                    },
                }
            );
            response.data.map((item) => {
                const mes = this.meses[item.mes - 1].toLowerCase();
                this.valores[mes]["desconto"] = item.desconto;
            });
        },
        async editarValor() {
            if (!this.$refs.formEditar.validate()) return;

            this.carregando = true;

            var data = {
                id_simulacao: this.item.id_simulacao,
                tipo: this.editar.tipo,
                obj: {},
            };

            data.obj[this.editar.tipo] = this.editar.valor;

            var response = await this.$http().post("/simulacao/pdv/editar", {
                data: data,
            });

            this.carregando = false;

            if (response.data.sucesso) {
                this.filtrar();
                this.editar.dialogo = false;
            } else {
                this.alerta = true;
                this.alertaColor = "error";
                this.alertaTimeout = -1;
                this.alertaTexto = response.data.msg;
            }
        },
        comparaVersoes(anterior, atual) {
            if (anterior === null) return false;
            return anterior != atual;
        },
    },
    async created() {
        this.carregando = true;

        // this.simulacao.ano = this.anoProximo;

        var response = await this.$http().post("/simulacao/pdv/filtros", {
            data: {},
        });

        this.lista = response.data;
        this.lista.meses = [];

        this.simulacao.usuarioTipo = this.lista.usuarioTipo;

        var classe = "dark";
        var colspan = this.lista.usuarioTipo == "rd" ? 3 : 2;
        /* if (this.lista.usuarioTipo !== "rd") {
			this.headersGroup0[0].colspan = 7;
			this.headersGroup[0].colspan = 7;
		} */
        // Adicionar colunas em branco na header
        this.headersGroup0.push(
            {
                class: "sticky-col first-col",
            },
            {
                class: "sticky-col second-col",
            },
            {
                class: "sticky-col third-col",
            }
        );
        this.headersGroup.push(
            {
                class: "sticky-col first-col",
            },
            {
                class: "sticky-col second-col",
            },
            {
                class: "sticky-col third-col",
            }
        );

        this.headersGroup0.push({
            text: "Verão",
            colspan: 3 * colspan,
            icon: "mdi-white-balance-sunny",
            class: "v-data-table-header text-center verao",
            color: "#c28f5b",
        });
        this.headersGroup0.push({
            text: "Inverno",
            colspan: 5 * colspan,
            icon: "mdi-snowflake",
            class: "v-data-table-header text-center inverno",
            color: "#1e7199",
        });
        this.headersGroup0.push({
            text: "Verão",
            colspan: 4 * colspan,
            icon: "mdi-white-balance-sunny",
            class: "v-data-table-header text-center verao",
            color: "#c28f5b",
        });

        for (let m = 1; m <= 12; m++) {
            const cellBg = `${
                4 <= m && m <= 8 ? "inverno" : "verao"
            }-${classe}`;
            this.headersGroup.push({
                text: this.meses[m - 1],
                colspan: colspan,
                class: "v-data-table-header text-center " + cellBg,
            });

            if (this.lista.usuarioTipo == "rd") {
                this.headers.push({
                    text: "Desconto",
                    value: "desconto_" + m,
                    align: "center",
                    class: cellBg + "  th-separador min-width-150",
                });
            }

            this.headers.push({
                text: "Lojas",
                value: "filiais_" + m,
                align: "center",
                class: cellBg + " th-separador min-width-150",
            });
            this.headers.push({
                text: "R$",
                value: "valor_" + m,
                align: "center",
                class: cellBg + " min-width-150",
            });

            classe = classe == "dark" ? "light" : "dark";
        }

        this.headersGroup.push({
            text: "Total",
            colspan: 2,
            class: "v-data-table-header text-center  " + classe,
        });

        this.headers.push({
            text: "Lojas",
            value: "filiais",
            align: "center",
            class: classe + " th-separador",
        });
        this.headers.push({
            text: "R$",
            value: "valor",
            align: "center",
            class: classe,
        });

        this.fornecedorMarca();
        this.filtrar();
    },
};
</script>

<style>
.valorTotal {
    color: #000 !important;
    text-align: center !important;
}

/* .v-card__title {
	background-color: #f3b36c !important;
} */

.primary-degrade {
    background: linear-gradient(90deg, #68c797, #259c8b);
}
.radius-10 {
    border-radius: 10px !important;
}
/* Congelar colunas   */
.simulation-table.pdv-table td:nth-child(6),
.simulation-table.pdv-table td:nth-child(7),
.simulation-table.pdv-table td:nth-child(8),
/* .simulation-table.pdv-table .first-col,
.simulation-table.pdv-table .second-col, */
.sticky-col {
    position: -webkit-sticky;
    position: sticky;
    overflow: hidden;
    background: #fff;
    z-index: 1;
}
.simulation-table.pdv-table .v-data-table__selected td:nth-child(6),
.simulation-table.pdv-table .v-data-table__selected td:nth-child(7),
.simulation-table.pdv-table .v-data-table__selected td:nth-child(8) {
    background: #f5f5f5;
}
.simulation-table.pdv-table tr:hover td {
    background-color: #f8f8f8;
}

.simulation-table.pdv-table td:nth-child(6),
.simulation-table.pdv-table .first-col {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
    left: 0px;
}

.simulation-table.pdv-table td:nth-child(7),
.simulation-table.pdv-table .second-col {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
    left: 150px;
}

.simulation-table.pdv-table td:nth-child(8),
.simulation-table.pdv-table .third-col {
    width: 130px;
    min-width: 130px;
    max-width: 130px;
    left: 300px;
    white-space: normal;
}

.simulation-table.pdv-table .background-primary {
    background: var(--v-primary-base);
}
.table-separator:nth-child(2) tr:nth-child(2) {
    border-left: 1px solid;
    border-image: linear-gradient(
        to bottom,
        transparent 25%,
        rgba(0, 0, 0, 0.1) 25%,
        red 50%,
        red 50%,
        rgba(0, 0, 0, 0.1) 75%,
        transparent 75%
    );
    border-image-slice: 1;
}
.simulation-table .v-data-table-header__icon {
    opacity: 0.5;
}
.w-100 {
    width: 100% !important;
}
/* /* .new-button {
		font-size: 1.5rem !important;
		padding: 10px;
		height: 60px !important;
		width: 200px;
		border-radius: 10px;
} */
.new-button {
    min-height: 40px;
    font-size: 1rem !important;
    font-weight: 500;
}
.v-input__slot:before {
    display: none;
}
.v-input__slot:after {
    display: none;
}
.v-data-footer .v-input {
    background: #f2f2f2 !important;
    border-radius: 6px;
    padding-left: 15px;
    padding-right: 5px;
}

.v-data-footer .v-btn--round {
    background-color: #f2f2f2;
    width: 24px;
    height: 24px;
}
.v-data-footer .v-btn--round .v-icon {
    font-size: 20px;
}

.simulation-table .input-cinza input {
    color: #808080 !important;
    font-size: 0.875rem !important ;
}
*,
*:before,
*:after {
    box-sizing: border-box;
}
.diff {
    background-color: pink !important;
}
.diff fieldset {
    background-color: pink !important;
}
.quebra-linha {
    white-space: normal;
}
.involucro {
    height: 650px;
    display: flex;
  margin-top: 20px;
  /* flex-grow: 1; */
  /* overflow: hidden; */
  display: flex;
  flex-direction: column;
}

.involucro .flex-table {
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  height: 620px;
  width: 100%;
}

.involucro .flex-table > div {
  width: 100%;
}

.involucro thead {
	position: -webkit-sticky;
	position: sticky;
	overflow: hidden;
	z-index: 4;
}

.involucro .header0 {
	top: 0px;
	background-color: #ffff;
    z-index: 4;
}

.involucro .flex-table .v-data-table-header {
	top: 90px;
    z-index: 4;
} 

.involucro .flex-table .v-data-table__wrapper {
	overflow-y: auto !important;
} 

</style>
